import { Directive, DirectiveBinding } from 'vue';
import REQUIRED_ROLES from '@/constants/required-roles';
import { FeatureName } from '@/enums/feature-names';
import Role from '@/enums/role';
import hasAnyRole from '@/helpers/has-any-role';

const PermissionDirective: Directive<HTMLElement, FeatureName> = (element: HTMLElement, binding: DirectiveBinding<FeatureName>): void => {
    const requiredRoles: Role[] = REQUIRED_ROLES[binding.value];

    const hasPermission: boolean = hasAnyRole(requiredRoles);

    if (hasPermission) {
        return;
    }

    element.remove();
};

export default PermissionDirective;
