import { jwtDecode, JwtPayload } from 'jwt-decode';
import { defineStore } from 'pinia';
import { computed, ComputedRef, type Ref, ref } from 'vue';
import Role from '@/enums/role';

const mainStoreKey: string = 'main';

const mainStore = defineStore(mainStoreKey, () => {
    const accessToken: Ref<string> = ref(' ');
    const username: Ref<string | null> = ref(null);
    const userRoles: Ref<Role[]> = ref([]);

    const setAccessToken = (token: string): void => {
        accessToken.value = token;
        const decodedUserToken: JwtPayload & { roles: Role[] } = jwtDecode(token);
        userRoles.value = decodedUserToken.roles;
    };

    const setUsername = (name: string): void => {
        username.value = name;
    };

    const isMaster: ComputedRef<boolean> = computed((): boolean => !!userRoles.value?.includes(Role.MASTER));
    const isGPPUser: ComputedRef<boolean> = computed((): boolean => !!userRoles.value?.includes(Role.GPP_USER));
    const isShipITCountryLead: ComputedRef<boolean> = computed((): boolean => !!userRoles.value?.includes(Role.SHIPIT_COUNTRY_LEAD));

    return {
        accessToken,
        setAccessToken,
        setUsername,
        username,
        userRoles,
        isMaster,
        isGPPUser,
        isShipITCountryLead
    };
});

export default mainStore;
