import { GlsFeatureFlagsConfig } from '@gls-its-frontends/common/gls-feature-flags';
import { type EnvironmentConfig } from '@/types/environment';

const devEnvironment: EnvironmentConfig = {
    VITE_APIGEE_API: 'https://api-dev.gls-group.net',
    VITE_APIGEE_API_VERSION: '/attribute-store-v1/api/v1',
    VITE_AZURE_AD_CLIENT_ID: '94f7808a-d2dd-4163-8834-a163d7138de0'
};

const stageEnvironment: EnvironmentConfig = {
    VITE_APIGEE_API: 'https://api-qas.gls-group.net',
    VITE_APIGEE_API_VERSION: '/attribute-store-v1/api/v1',
    VITE_AZURE_AD_CLIENT_ID: '94f7808a-d2dd-4163-8834-a163d7138de0'
};

const mt1Environment: EnvironmentConfig = {
    VITE_APIGEE_API: 'https://api-qas.gls-group.net',
    VITE_APIGEE_API_VERSION: '/mt1-attribute-store-v1/api/v1',
    VITE_AZURE_AD_CLIENT_ID: '94f7808a-d2dd-4163-8834-a163d7138de0'
};

const prodEnvironment: EnvironmentConfig = {
    VITE_APIGEE_API: 'https://api.gls-group.net',
    VITE_APIGEE_API_VERSION: '/attribute-store-v1/api/v1',
    VITE_AZURE_AD_CLIENT_ID: '6c7c9e6e-cd1e-49b8-b0b8-7acdd0268355'
};

let featureFlagsConfig: GlsFeatureFlagsConfig = {};

const getFeatureFlagsConfig = (): GlsFeatureFlagsConfig => {
    return { ...featureFlagsConfig };
};
const setFeatureFlagsConfig = async (): Promise<void> => {
    const featureFlagsResponse: Response = await fetch('/feature-flags.json');
    const config: GlsFeatureFlagsConfig = await featureFlagsResponse.json();
    featureFlagsConfig = config;
};

export { devEnvironment, stageEnvironment, mt1Environment, prodEnvironment, getFeatureFlagsConfig, setFeatureFlagsConfig };
