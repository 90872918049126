import { glsHasAnyRole } from '@gls-its-frontends/common/gls-roles';
import useMainStore from '@/composables/use-main-store';
import Role from '@/enums/role';

const hasAnyRole = (roles: Role[]): boolean => {
    const { userRoles } = useMainStore();

    return glsHasAnyRole(userRoles ?? [], roles);
};

export default hasAnyRole;
